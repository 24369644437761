import React, { useState, useContext } from "react"
import cargoVideo from "../videos/urbanArrow.mp4"
import { useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Img from "gatsby-image"
import CartContext from "../contexts/CartContext"

import styles from "./cargo.module.scss"
import Seo from "../components/seo"

import Layout from "../components/layout"
import SwiperSlider from "../components/swiperSlider"

const Cargo = () => {
  const [activeColorOption, setActiveColorOption] = useState("Blanc")
  const [activeTab, setActiveTab] = useState(1)
  const [geometryToggle, setGeometryToggle] = useState(true)
  const { addItem } = useContext(CartContext)
  const data = useStaticQuery(graphql`
    query {
      allStrapiCargo(filter: { titre: { eq: "Urban Arrow Family" } }) {
        nodes {
          images {
            url
          }
        }
      }
      moteur: file(relativePath: { eq: "images/moteur/moteur.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      batterie: file(relativePath: { eq: "images/batterie/batterie.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      urbanArrowGeometry: file(
        relativePath: { eq: "images/cargo/urbanArrowGeometry.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo
        title="Les vélos cargos électriques"
        description="Sélection des vélos cargos électriques"
      />
      <main id="topOfPage" className={styles.mainContainer}>
        <section className={styles.topSection}>
          <h4 className="dispo">Livraison 2 semaines</h4>
          <h1 className="category">Vélos Cargo</h1>
        </section>
        <div className={styles.upperBracket}></div>
        <section className={styles.bikeVisuals}>
          <SwiperSlider data={data.allStrapiCargo} />
        </section>
        <div className={styles.lowerBracket}></div>
        <section className={styles.bikeSpecs}>
          <section className={styles.specs1}>
            <h3>Urban Arrow</h3>
            <ul>
              <AnchorLink to="/cargo#moteur">
                <li>
                  <div className={styles.cross}></div>
                  <p>Moteur Bosch Perf. Line</p>
                </li>
              </AnchorLink>
              <AnchorLink to="/cargo#batterie">
                <li>
                  <div className={styles.cross}></div>
                  <p>Batterie Bosch 500 wh</p>
                </li>
              </AnchorLink>
              <AnchorLink to="/cargo#freins">
                <li>
                  <div className={styles.cross}></div>
                  <p>Un biporteur pour la famille !</p>
                </li>
              </AnchorLink>
              <li>
                <div className={styles.circle}></div>
                <p>Tente contre la pluie et le froid</p>
              </li>
              <li>
                <div className={styles.circle}></div>
                <p>Agilité en ville</p>
              </li>
              <li>
                <div className={styles.circle}></div>
                <p>Capacité : 200kg </p>
              </li>
            </ul>
          </section>
          <section className={styles.specs2}>
            <h4 className={styles.price}>4 690,00 €</h4>
            <section className={styles.quantity}>
              <label htmlFor="taille">Couleur</label>
              <section className={styles.quantityOptionsContainer}>
                <div className={styles.selectionShape}></div>
                <div
                  onClick={e => setActiveColorOption("Blanc")}
                  onKeyDown={e =>
                    e.key === "w" ?? setActiveColorOption("Blanc")
                  }
                  tabIndex="0"
                  role="button"
                  className={
                    activeColorOption !== "Blanc"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  Blanc
                </div>
                <div
                  onClick={e => setActiveColorOption("Noir")}
                  onKeyDown={e => e.key === "n" ?? setActiveColorOption("Noir")}
                  tabIndex="0"
                  role="button"
                  className={
                    activeColorOption !== "Noir"
                      ? styles.options
                      : `${styles.options} ${styles.activeOption}`
                  }
                >
                  Noir
                </div>
              </section>
            </section>
            <input
              type="submit"
              onClick={() =>
                addItem(
                  "Urban Arrow",
                  `${activeColorOption}`,
                  1,
                  4690,
                  "cargo/cargo.jpg"
                )
              }
              value="Acheter"
            />
          </section>
        </section>
        <section id="moteur" className={styles.specs3}>
          <article className={styles.specs3a}>
            <h2>Moteur Bosh Performance Line</h2>
            <br />
            <section className={styles.frame2}>
              <Img
                className={styles.image}
                fluid={data.moteur.childImageSharp.fluid}
                alt="moteur pour vélo électrique de la marque Bosch"
              />
            </section>
            <p className={styles.text}>
              La motorisation Performance Line représente le système de
              motorisation le plus polyvalent de Bosch : qu'il s'agisse de
              rouler à travers champs à une allure sportive, de se rendre au
              travail ou de se balader en toute décontraction pendant ses
              loisirs, elle offre toujours la bonne dose d'assistance au bon
              moment. Elle est disponible en deux versions. La motorisation
              Performance Cruise assiste jusqu'à 25 km/h grâce à son couple
              maximal de 65 Nm et séduit par son comportement au pédalage
              naturel et sportif. Ainsi, le plaisir de rouler est absolu, même
              sur terrain vallonné. La motorisation Performance Speed permet
              d'atteindre des vitesses élevées encore plus rapidement, pour
              couvrir de longues distances sans forcer. Elle assiste les VAE
              Speed jusqu'à 45 km/h et garantit des trajets quotidiens encore
              plus rapides à partir de l'année-modèle 2021 grâce à un couple
              maximal de 85 Nm.
            </p>
          </article>
        </section>
        <section id="batterie" className={styles.specs4}>
          <article className={styles.specs4a}>
            <h2>Batterie 500wh</h2>
            <br />
            <section className={styles.frame3}>
              <Img
                className={styles.image}
                fluid={data.batterie.childImageSharp.fluid}
                alt="batterie pour vélo électrique de la marque Bosch"
              />
            </section>
            <p className={styles.text}>
              La PowerTube 500 permet aux VTT et vélos de trekking de parcourir
              sans problème de longues distances. Grâce à ses dimensions
              compactes et à sa structure minimaliste, cette batterie s'intègre
              parfaitement au design du VAE. La technique sophistiquée des
              batteries lithium-ion pouvant être intégrées au cadre garantit le
              plaisir de rouler en toute liberté. De plus, une sécurité les
              empêche de tomber, même sur terrains difficiles. Les batteries se
              retirent toutefois très aisément.
            </p>
          </article>
        </section>
        <section className={styles.upSection}>
          <AnchorLink className={styles.topOfPage} to="/cargo#topOfPage">
            <div className={styles.upArrow}></div>
          </AnchorLink>
        </section>
        <section id="freins" className={styles.specs5}>
          <article>
            <h2>Un biporteur pour la famille !</h2>
            <br />
            <p className={styles.text}>
              Le vélo cargo électrique Urban Arrow Family s'adresse directement
              aux citadins qui recherchent une alternative crédible à la voiture
              ou aux transports en commun, pour leurs déplacements quotidiens !
              Avec ce modèle de biporteur, les parents trouveront un superbe
              moyen pour se déplacer sereinement en ville, un modèle classe au
              design abouti et à la capacité de charge impressionnante !
            </p>
          </article>
        </section>
        <section className={styles.videoSection}>
          <section className={styles.videoContainer}>
            <video controls>
              <source src={cargoVideo} type="video/mp4" />
              <track
                label="English"
                kind="captions"
                srclang="en"
                default
              ></track>
            </video>
          </section>
        </section>
        <section className={styles.specs6}>
          <div className={styles.tabTitles}>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setActiveTab(1)}
              onKeyDown={e => e.key === "a" ?? setActiveTab(1)}
              className={
                activeTab === 1 ? styles.activeTabTitle1 : styles.tabTitle
              }
            >
              Equipement
            </div>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setActiveTab(2)}
              onKeyDown={e => e.key === "b" ?? setActiveTab(2)}
              className={
                activeTab === 2 ? styles.activeTabTitle2 : styles.tabTitle
              }
            >
              Moteur et <br /> batterie
            </div>
            <div
              role="button"
              tabIndex="0"
              onClick={() => setActiveTab(3)}
              onKeyDown={e => e.key === "c" ?? setActiveTab(3)}
              className={
                activeTab === 3 ? styles.activeTabTitle3 : styles.tabTitle
              }
            >
              Géométrie
            </div>
          </div>
          <div className={styles.tabs}>
            {activeTab === 1 && (
              <div className={styles.tab1}>
                <table>
                  <tbody>
                    <tr>
                      <th>Couleur</th>
                      <td>action team</td>
                    </tr>
                    <tr>
                      <th>Cadre</th>
                      <td>
                        C:62 Monocoque Advanced Twin Mold Technology, Aluminum
                        6061 T6 Rear Triangle, Efficient Trail Control, FSP
                        4-Link, Agile Trail Geometry, Boost 148, Full Integrated
                        Battery
                      </td>
                    </tr>
                    <tr>
                      <th>Fourche</th>
                      <td>
                        Fox 36 Float Factory FIT4, 3-Position w/Low Speed
                        Compression Adjust, Tapered, 15x110mm, E-Bike Optimized,
                        150mm, Kashima Coated
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Amort-
                        <br />
                        isseur
                      </th>
                      <td>
                        Fox Float DPX2 Factory EVOL, 185x55mm,
                        Open(Adjustable)/Medium/Firm Mode, Trunnion Mount,
                        Kashima Coated
                      </td>
                    </tr>
                    <tr>
                      <th>Freins</th>
                      <td>Shimano XT BR-M8120, Hydr. Disc Brake (203/203)</td>
                    </tr>
                    <tr>
                      <th>Selle</th>
                      <td>Natural Fit Venec</td>
                    </tr>
                    <tr>
                      <th>Tige de selle</th>
                      <td>
                        Fox Transfer Factory 31.6mm, Kashima Coated (XS, S:
                        100mm, M: 125mm, L: 150mm, XL: 175mm)
                      </td>
                    </tr>
                    <tr>
                      <th>Pédalier</th>
                      <td>
                        e*thirteen Plus Crank, 165mm (27.5: 36T // 29: 34T)
                      </td>
                    </tr>
                    <tr>
                      <th>Dérailleur arrière</th>
                      <td>Shimano XT RD-M8100-SGS, ShadowPlus, 12-Speed</td>
                    </tr>
                    <tr>
                      <th>Cassette</th>
                      <td>Shimano Deore CS-M6100, 10-51T</td>
                    </tr>
                    <tr>
                      <th>Pneu avant</th>
                      <td>
                        Schwalbe Magic Mary, Super Trail, Addix Soft, Kevlar,
                        2.4
                      </td>
                    </tr>
                    <tr>
                      <th>Pneu arrière</th>
                      <td>
                        Schwalbe Big Betty, Super Gravity, Addix Soft, Kevlar,
                        2.4
                      </td>
                    </tr>
                    <tr>
                      <th>Ecran</th>
                      <td>Bosch Nyon</td>
                    </tr>
                    <tr>
                      <th>Taille des roues</th>
                      <td>
                        Size Split: 27.5: XS (15") // 29: S (16"), M (18"), L
                        (20"), XL (22")
                      </td>
                    </tr>
                    <tr>
                      <th>Poids total</th>
                      <td>à partir de 24,3 kg</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {activeTab === 2 && (
              <section className={styles.tab2}>
                <h2>Moteur</h2>
                <table>
                  <tbody>
                    <tr>
                      <th>Type de transmission</th>
                      <td>Dérailleur/Moyeu</td>
                    </tr>
                    <tr>
                      <th>Niveau d'assistance (%)</th>
                      <td>
                        TURBO: 340 <br />
                        eMTB: 140-340 <br />
                        TOUR: 140 <br />
                        ECO: 60
                      </td>
                    </tr>
                    <tr>
                      <th>Couple maximal possible (Nm)</th>
                      <td>
                        TURBO: 85 <br />
                        eMTB: 85 <br />
                        TOUR: 50 <br />
                        ECO: 40
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Assistance jusque dans les fréquences <br /> de pédalage
                        maximales (tr/min)
                      </th>
                      <td>&gt; 120</td>
                    </tr>
                    <tr>
                      <th>Comportement au démarrage</th>
                      <td>Très sportif</td>
                    </tr>
                    <tr>
                      <th>Assistance maximale jusqu'à</th>
                      <td>25 km/h</td>
                    </tr>
                    <tr>
                      <th>Détection des changements de vitesse</th>
                      <td>Dynamique</td>
                    </tr>
                    <tr>
                      <th>Poids</th>
                      <td>2,9 kg</td>
                    </tr>
                  </tbody>
                </table>
                <h2>Batterie</h2>
                <table className={styles.batterieTable}>
                  <thead>
                    <tr>
                      <th aria-label="thead"></th>
                      <th>Compact Charger</th>
                      <th>Standard Charger</th>
                      <th>Fast Charger</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>50 % Charge</th>
                      <td>env. 4,2h</td>
                      <td>env. 2,1h</td>
                      <td>env. 1,4h</td>
                    </tr>
                    <tr>
                      <th>100 % charge</th>
                      <td>env. 8,8h</td>
                      <td>env. 4,9h</td>
                      <td>env. 3,7h</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
            {activeTab === 3 && (
              <section className={styles.tab3}>
                <section className={styles.switchSection}>
                  <h3>Tableau</h3>
                  <div
                    aria-label="toggleTabletoDrawing"
                    role="button"
                    tabIndex="0"
                    onClick={() => setGeometryToggle(!geometryToggle)}
                    onKeyDown={e =>
                      e.key === "t"
                        ? setGeometryToggle(!geometryToggle)
                        : setGeometryToggle(geometryToggle)
                    }
                    className={
                      geometryToggle
                        ? styles.tgl
                        : `${styles.tgl} ${styles.tglRight}`
                    }
                  ></div>
                  <h3>Dessin technique</h3>
                </section>
                {geometryToggle && (
                  <section className={styles.cubeStereoGeometryTable}>
                    <table>
                      <thead>
                        <tr>
                          <th aria-label="thead"></th>
                          <th>16"</th>
                          <th>18"</th>
                          <th>20"</th>
                          <th>22"</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Tube de selle</th>
                          <td>375</td>
                          <td>420</td>
                          <td>470</td>
                          <td>520</td>
                        </tr>
                        <tr>
                          <th>Tube supérieur</th>
                          <td>571</td>
                          <td>592</td>
                          <td>616</td>
                          <td>642</td>
                        </tr>
                        <tr>
                          <th>Angle de tube de direction</th>
                          <td>65,6</td>
                          <td>65,6</td>
                          <td>65,6</td>
                          <td>65,6</td>
                        </tr>
                        <tr>
                          <th>Base arrière</th>
                          <td>457</td>
                          <td>457</td>
                          <td>457</td>
                          <td>457</td>
                        </tr>
                        <tr>
                          <th>Tube de direction</th>
                          <td>105</td>
                          <td>108</td>
                          <td>118</td>
                          <td>138</td>
                        </tr>
                        <tr>
                          <th>Empattement</th>
                          <td>1189</td>
                          <td>1210</td>
                          <td>1235</td>
                          <td>1262</td>
                        </tr>
                        <tr>
                          <th>Débattement arrière</th>
                          <td>140</td>
                          <td>140</td>
                          <td>140</td>
                          <td>140</td>
                        </tr>
                        <tr>
                          <th>Hauteur de l'enjambement</th>
                          <td>777</td>
                          <td>777</td>
                          <td>777</td>
                          <td>813</td>
                        </tr>
                        <tr>
                          <th>Taille de roue</th>
                          <td>29</td>
                          <td>29</td>
                          <td>29</td>
                          <td>29</td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                )}
                {!geometryToggle && (
                  <section className={styles.cubeStereoGeometrySvg}>
                    <Img
                      className={styles.image}
                      fluid={data.urbanArrowGeometry.childImageSharp.fluid}
                      alt="."
                    />
                  </section>
                )}
              </section>
            )}
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default Cargo
